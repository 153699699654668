import { NavLink } from 'react-router-dom';
import { Box, useTheme, Button, Paper, Typography } from '@mui/material';

function AppCard({
    title,
    description,
    link,
}: {
    title: string;
    description: string;
    link: string;
}) {
    const charLimit = 100;
    const theme = useTheme();
    let modifiedDescription = '';
    if (description.length >= charLimit) {
        modifiedDescription = description.substring(0, charLimit);
    } else {
        const spaceToAdd = charLimit - description.length;
        modifiedDescription = `${description}${'\xa0 '.repeat(spaceToAdd / 2)}`;
    }

    return (
        <Paper
            elevation={3}
            sx={{
                maxWidth: theme.spacing(40),
                padding: theme.spacing(3),
                textAlign: 'center',
            }}
        >
            <Box style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h5">{title}</Typography>
                <Typography
                    variant="caption"
                    sx={{ marginTop: theme.spacing(1), textAlign: 'left' }}
                >
                    {modifiedDescription}
                </Typography>
                <Button
                    component={NavLink}
                    to={link}
                    variant="contained"
                    sx={{ marginTop: theme.spacing(4) }}
                >
                    Go
                </Button>
            </Box>
        </Paper>
    );
}

export default AppCard;
