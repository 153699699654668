import { Box, Container, Grid, useTheme } from '@mui/material';
import AppCard from './AppCard';
import { TApp } from '../../utils/types';

const APPS: TApp[] = [
    {
        name: 'JOSE Tools',
        description: 'Various tools for JOSE.',
        link: 'https://jose-tools.ezylabs.work',
    },
    {
        name: 'Bottoms Up',
        description:
            'Encrypt and share your secret, with assurance that it can only be read once.',
        link: 'https://bottoms-up.ezylabs.work',
    },
    {
        name: 'Boring Poker',
        description:
            'A very boring poker tool for estimating task in scrum. Be aware, you may need cofee after using this.',
        link: 'https://boring-poker.ezylabs.work',
    },
];
function AppPage() {
    const theme = useTheme();

    return (
        <Container fixed>
            <Box mt={2} />
            <Grid container>
                {APPS.map((app, idx) => (
                    <Grid key={idx} sx={{ margin: theme.spacing(1) }}>
                        <AppCard
                            title={app.name}
                            description={app.description}
                            link={app.link}
                        />
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
}
export default AppPage;
